@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.location-section {
  position: relative;
  z-index: 2;
  scroll-margin-top: 4.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.location-section .location-title {
  font-size: 1.5rem;
  color: #5c5c66;
  font-weight: lighter;
  font-style: italic;
  background-color: #ffffff;
  padding: 0.25rem 2rem;
  position: absolute;
  top: 1rem;
  border-radius: 2rem;
}
.location-section .map-container {
  width: 100%;
}
.location-section .btn-map-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 0;
  right: 4rem;
  z-index: 1;
}
.location-section .btn-map-selector .btn-map {
  margin-right: 1rem;
  cursor: pointer;
  width: 6rem;
  height: 2rem;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 0.2rem;
  color: #5c5c66;
  background-position: center;
  font-family: ClanProRgNews;
}
.location-section .btn-map-selector .btn-map:hover {
  background: #5c5c66;
  color: #ffffff;
  border-color: #5c5c66;
}
.location-section .btn-map-selector .btn-map:active {
  background-color: #e2d3bc;
  background-size: 100%;
  transition: background 0s;
}
.location-section .waze-btn {
  display: flex;
  position: absolute;
  border-radius: 0 6.25rem 6.25rem 0;
  border: 1px solid #ebe5c5;
  background-color: #ebe5c5;
  top: 20%;
  width: 20%;
  height: 5rem;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.location-section .waze-btn:link {
  color: #000000;
  text-decoration: none;
}
.location-section .waze-btn:visited {
  color: #000000;
}
.location-section .waze-btn:active {
  color: #000000;
}
.location-section .waze-btn .waze-icon {
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  z-index: 2;
}
.location-section .waze-btn .text-btn-container {
  margin-right: 1rem;
  font-size: 1.2rem;
  font-family: SarabunLight;
  color: #243455;
}
.location-section .location-3d-container {
  height: 32rem;
}
.location-section .location-video-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #dfdfdf;
}
.location-section .location-video-container .location-video {
  height: 100%;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .location-section {
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .location-section .location-title {
    font-size: 1.2rem;
  }
  .location-section .btn-map-selector {
    position: unset;
    margin-top: 3rem;
  }
  .location-section .btn-map-selector {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }
  .location-section .btn-map {
    margin-right: 0 !important;
  }
  .location-section .waze-btn {
    top: 6rem;
    left: -2rem;
  }
  .location-section .map-container {
    height: 15rem;
  }
  .location-section .location-img {
    width: 170%;
    position: relative;
    right: 6rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .location-title {
    font-size: 1.2rem !important;
  }
}/*# sourceMappingURL=LocationSection.css.map */