@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.gallery-hor-section {
  z-index: 1;
  bottom: -50%;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
}
.gallery-hor-section .gallery-hor-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 5rem;
  row-gap: 2rem;
}
.gallery-hor-section .gallery-hor-subcontainer .gh-title {
  font-size: 1.5rem;
  font-weight: lighter;
  font-style: italic;
  color: #5c5c66;
  background-color: #ffffff;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
}
.gallery-hor-section .fs-gallery-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
.gallery-hor-section .fs-gallery-container .reset-values-gallery {
  position: absolute;
  width: 6rem;
  height: 30rem;
  z-index: 9999;
  top: -35rem;
  cursor: pointer;
}
.gallery-hor-section .carousel-mobile-hor {
  display: none;
  z-index: 2;
}
.gallery-hor-section .carousel-mobile-hor .btn-container {
  z-index: 3;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.gallery-hor-section .carousel-mobile-hor .btn-gallery {
  margin-right: 0.5rem;
  width: 6.5rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 0.2rem;
  background-color: #6bb301;
  border: 1px solid #6bb301;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-position: center;
  transition: background 0.8s;
  font-family: ClanProRgNews;
  font-size: 0.8rem;
}
.gallery-hor-section .carousel-mobile-hor .btn-gallery:hover {
  background: #90da21 radial-gradient(circle, transparent 1%, #90da21 1%) center/15000%;
  border: 1px solid #90da21;
}
.gallery-hor-section .carousel-mobile-hor .btn-gallery:active {
  background-color: #e2d3bc;
  background-size: 100%;
  transition: background 0s;
}
.gallery-hor-section .carousel-mobile-hor .btn-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .gallery-hor-section {
    height: 26rem;
  }
  .gallery-hor-subcontainer {
    width: 100% !important;
    margin-top: 1rem !important;
    row-gap: 1rem !important;
  }
  .gallery-container {
    display: none !important;
  }
  .gh-title {
    font-size: 1.2rem !important;
  }
  .carousel-mobile-hor {
    display: unset !important;
    height: 18rem;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .gallery-hor-section {
    height: 22rem;
  }
  .gallery-hor-subcontainer {
    width: 100% !important;
    margin-top: 1rem !important;
    row-gap: 1rem !important;
  }
  .gallery-container {
    display: none !important;
  }
  .gh-title {
    font-size: 1.2rem !important;
  }
  .carousel-mobile-hor {
    display: unset !important;
    height: 18rem;
  }
}
/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .gallery-hor-subcontainer {
    width: 100% !important;
  }
  .gh-title {
    font-size: 1rem !important;
  }
}/*# sourceMappingURL=EndGallerySection.css.map */