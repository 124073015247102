@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.benefit-section {
  z-index: 1;
  bottom: -50%;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
}

.benefit-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}

.benefit-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.benefit-title-container .title-section {
  font-size: 1.5rem;
  font-weight: lighter;
  color: #5c5c66;
  background-color: #ffffff;
  padding: 0.6rem 2.5rem;
  font-style: italic;
  -webkit-border-bottom-right-radius: 1.5rem;
  -webkit-border-bottom-left-radius: 1.5rem;
  -moz-border-radius-bottomright: 1.5rem;
  -moz-border-radius-bottomleft: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.benefit-location-icon-container {
  width: 100%;
  margin: 1.25rem 0;
}
.benefit-location-icon-container .bl-icon {
  display: flex;
  align-items: center;
  width: 6rem;
  padding: 0.2rem 1.5rem;
  background-color: #ffffff;
  font-size: 0.65rem;
  -moz-column-gap: 0.25rem;
       column-gap: 0.25rem;
  border-radius: 1rem;
  color: #5c5c66;
  margin-left: 5%;
}
.benefit-location-icon-container .bl-icon-img {
  width: auto;
  height: 1rem;
}

.benefits-info-container {
  padding: 1.5rem 2%;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.benefits-info-container .benefit-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
}
.benefits-info-container .benefit-icon {
  width: 5rem;
  height: 5rem;
}
.benefits-info-container .benefit-subtitle {
  font-size: 1rem;
  color: #ffffff;
  text-align: center;
}

.carousel-mobile {
  display: none;
  height: 10rem;
}

.carousel-tablet {
  display: none;
}

.benefit-location-icon-container {
  display: flex;
  justify-content: space-between;
}

.bl-icon-copy {
  width: 13rem !important;
  justify-content: center;
}

.benefit-2024-txt-container {
  width: 100%;
}
.benefit-2024-txt-container .b2024t-subcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
  margin-top: 1rem;
}
.benefit-2024-txt-container .b2024t-subcontainer .b2024t-txt {
  width: 27rem;
  font-weight: lighter;
  color: #ffffff;
  font-size: 2rem;
  font-style: italic;
}
.benefit-2024-txt-container .b2024t-subcontainer .b2024t-deco-img {
  width: 7rem;
  height: auto;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .benefit-img-bg {
    height: 29rem;
  }
  .benefit-subcontainer {
    width: 100%;
    row-gap: 1rem;
  }
  .benefit-subcontainer .title-section {
    font-size: 1rem;
  }
  .benefit-subcontainer .benefits-info-container {
    display: none;
  }
  .benefit-subcontainer .carousel-mobile {
    display: unset;
    width: 100%;
  }
  .benefit-subcontainer .b2024t-subcontainer {
    margin: 1rem 5% 0;
  }
  .benefit-subcontainer .b2024t-subcontainer .b2024t-txt {
    font-size: 1rem !important;
    color: #5c5c66;
  }
  .benefit-subcontainer .b2024t-subcontainer .b2024t-deco-img {
    width: 5rem !important;
    height: auto;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .benefit-img-bg {
    height: 30rem;
  }
  .benefit-subcontainer {
    width: 100%;
    row-gap: 1rem;
  }
  .benefit-subcontainer .title-section {
    font-size: 1.2rem;
  }
  .benefit-subcontainer .benefits-info-container {
    display: none;
  }
  .benefit-subcontainer .carousel-tablet {
    display: unset;
    width: 100%;
  }
  .benefit-subcontainer .b2024t-subcontainer {
    margin: 1rem 0 0 3rem;
  }
  .benefit-subcontainer .b2024t-subcontainer .b2024t-txt {
    font-size: 1.5rem !important;
    color: #5c5c66;
  }
  .benefit-subcontainer .b2024t-subcontainer .b2024t-deco-img {
    width: 5rem !important;
    height: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .benefit-subcontainer {
    width: 100%;
    row-gap: 1rem;
  }
}/*# sourceMappingURL=BenefitSection.css.map */