@import "../../sass/variables";

.location-section {
  position: relative;
  z-index: 2;
  scroll-margin-top: 4.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .location-title {
    font-size: 1.5rem;
    color: $mainColorPrimary;
    font-weight: lighter;
    font-style: italic;
    background-color: $basicColorPrimary;
    padding: 0.25rem 2rem;
    position: absolute;
    top: 1rem;
    border-radius: 2rem;
  }
  .map-container {
    width: 100%;
  }
  .btn-map-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0;
    right: 4rem;
    z-index: 1;
    .btn-map {
      margin-right: 1rem;
      cursor: pointer;
      width: 6rem;
      height: 2rem;
      background-color: $basicColorPrimary;
      border: 1px solid $basicColorPrimary;
      border-radius: 0.2rem;
      color: $mainColorPrimary;
      background-position: center;
      font-family: $clanProRgNewsFont;
      &:hover {
        background: $mainColorPrimary;
        color: $basicColorPrimary;
        border-color: $mainColorPrimary;
      }
      &:active {
        background-color: $buttonColorPrimaryWave;
        background-size: 100%;
        transition: background 0s;
      }
    }
  }
  .waze-btn {
    display: flex;
    position: absolute;
    border-radius: 0 6.25rem 6.25rem 0;
    border: 1px solid $wazeButtonColor;
    background-color: $wazeButtonColor;
    top: 20%;
    width: 20%;
    height: 5rem;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &:link {
      color: $basicColorSecondary;
      text-decoration: none;
    }
    &:visited {
      color: $basicColorSecondary;
    }
    &:active {
      color: $basicColorSecondary;
    }
    .waze-icon {
      width: 3rem;
      height: 3rem;
      margin-right: 0.5rem;
      z-index: 2;
    }
    .text-btn-container {
      margin-right: 1rem;
      font-size: 1.2rem;
      font-family: $sarabunLightFont;
      color: $mainColorSecondary;
    }
  }
  .location-3d-container {
    height: 32rem;
  }
  .location-video-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #dfdfdf;
    .location-video {
      height: 100%;
    }
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .location-section {
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
    .location-title {
      font-size: 1.2rem;
    }
    .btn-map-selector {
      position: unset;
      margin-top: 3rem;
    }
    .btn-map-selector {
      column-gap: 0.5rem;
    }
    .btn-map {
      margin-right: 0 !important;
    }
    .waze-btn {
      top: 6rem;
      left: -2rem;
    }
    .map-container {
      height: 15rem;
    }
    .location-img {
      width: 170%;
      position: relative;
      right: 6rem;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .location-title {
    font-size: 1.2rem !important;
  }
}