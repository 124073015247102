@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.form-container {
  display: flex;
  position: fixed;
  top: 15%;
  z-index: 15;
  right: -25rem;
  width: 46rem;
}
.form-container .label-form {
  width: 20rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.form-container .label-form .label-head-text {
  border: 2px solid #6bb301;
  border-radius: 0px 0px 31px 31px;
  background-color: #6bb301;
  color: #ffffff;
  transform: rotate(90deg);
  position: relative;
  top: 0;
  left: -8.5rem;
  width: 20rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: ClanProMedium;
  background-position: center;
  transition: background 0.8s;
}
.form-container .label-form .label-head-text:hover {
  background: #90da21 radial-gradient(circle, transparent 1%, #90da21 1%) center/15000%;
}
.form-container .label-form .label-head-text:active {
  background-color: #e2d3bc;
  background-size: 100%;
  transition: background 0s;
}
.form-container .form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1.5rem;
  background-color: #ffffff;
  border-radius: 1rem;
  position: relative;
  left: -17.5rem;
}
.form-container .form-content .anchor-check {
  color: #000000;
}
.form-container .form-content .anchor-check:link {
  color: #000000;
  text-decoration: none;
}
.form-container .form-content .anchor-check:visited {
  color: #000000;
}
.form-container .form-content .anchor-check:active {
  color: #000000;
}
.form-container .form-content .form-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 1.5rem 0 0;
}
.form-container .form-content .names-container {
  display: flex;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.form-container .form-content .form-title {
  font-size: 1.3rem;
  font-family: ClanProMedium;
}
.form-container .form-content label {
  width: 100%;
}
.form-container .form-content .input {
  width: 100%;
  border-radius: 0.3rem;
  height: 2.5rem;
  padding-left: 1.2rem;
  border: 1px solid #6bb301;
  outline: none;
  font-family: SarabunLight;
}
.form-container .form-content .select {
  width: 109%;
}
.form-container .form-content .mandatory-text {
  font-size: 0.8rem;
  color: #ff0007;
  font-family: SarabunLight;
}
.form-container .form-content .hide {
  opacity: 0;
}
.form-container .form-content .label-advise {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: SarabunLightItalic;
}
.form-container .form-content .error {
  color: #ff0007;
}
.form-container .form-content .checkbox-input {
  margin-right: 0.5rem;
}
.form-container .form-content .submit-form {
  width: 100%;
  height: 2rem;
  cursor: pointer;
  border: 1px solid #6bb301;
  background-color: #6bb301;
  color: #ffffff;
  border-radius: 0.2rem;
  background-position: center;
  transition: background 0.8s;
  font-family: ClanProRgNews;
}
.form-container .form-content .submit-form:hover {
  background: #90da21 radial-gradient(circle, transparent 1%, #90da21 1%) center/15000%;
}
.form-container .form-content .submit-form:active {
  background-color: #e2d3bc;
  background-size: 100%;
  transition: background 0s;
}

.closed {
  animation-name: close;
  animation-duration: 1s;
  right: -43.7rem;
}
@keyframes close {
  0% {
    right: -22.5rem;
  }
  100% {
    right: -43.7rem;
  }
}

.open {
  animation-name: openForm;
  animation-duration: 1s;
  right: -22.5rem;
}
@keyframes openForm {
  0% {
    right: -43.7rem;
  }
  100% {
    right: -22.5rem;
  }
}

.deployed {
  position: unset;
  width: 100%;
  display: unset;
}

.hiden-label {
  display: none !important;
}

.form-deployed {
  position: unset !important;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .form-container {
    position: unset !important;
    width: 100%;
    justify-content: center;
  }
  .form-container .label-form {
    display: none;
  }
  .form-container .form-title {
    font-size: 1.1rem !important;
  }
  .form-container .names-container {
    flex-direction: column !important;
    width: 100%;
  }
  .form-container label {
    margin-bottom: 0 !important;
  }
  .form-container .form-input-container {
    width: 100%;
    margin: 1.25rem 0 0 0 !important;
  }
  .form-container .input {
    width: 91% !important;
  }
  .form-container .form-content {
    position: unset;
    width: 100%;
    border-radius: unset;
    height: 100% !important;
  }
  .form-container .submit-form {
    margin-top: 2rem;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .form-container {
    position: static;
    width: 100%;
    justify-content: center;
  }
  .form-container .label-form {
    display: none;
  }
  .form-container .form-title {
    font-size: 1.1rem !important;
  }
  .form-container .names-container {
    flex-direction: column !important;
    width: 100%;
  }
  .form-container label {
    margin-bottom: 0 !important;
  }
  .form-container .form-input-container {
    width: 100%;
  }
  .form-container .form-content {
    position: unset;
    height: 100% !important;
    width: 100%;
    border-radius: unset;
  }
  .form-container .submit-form {
    margin-top: 2rem;
  }
}
/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .form-container {
    position: unset !important;
    width: 100%;
    justify-content: center;
  }
  .form-container .label-form {
    display: none;
  }
  .form-container .form-title {
    font-size: 1.1rem !important;
  }
  .form-container label {
    margin-bottom: 0 !important;
  }
  .form-container .form-input-container {
    width: 100%;
  }
  .form-container .form-content {
    position: unset;
    height: 100% !important;
  }
  .form-container .submit-form {
    margin-top: 2rem;
  }
}
/*Responsive Tablet Standard*/
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .form-title {
    font-size: 1.1rem !important;
  }
}
/*Responsive Small Screen*/
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .form-container .form-content {
    height: unset;
  }
  .form-container .form-deployed {
    width: 90% !important;
  }
}
@media screen and (min-width: 1920px) {
  .form-deployed {
    width: 90% !important;
  }
}/*# sourceMappingURL=Form.css.map */