@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.home-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.home-section .home-tag-container {
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.home-section .home-tag-container .ht-subcontainer {
  width: 100%;
  position: relative;
  height: 560px;
}
.home-section .tag-img {
  position: absolute;
  top: 3rem;
  right: 15rem;
  z-index: 1;
  width: 11rem;
  height: auto;
  transform: rotate(-16deg);
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .home-section {
    height: 17rem;
  }
  .tag-img {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .home-section {
    height: 17rem;
  }
  .tag-img {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tag-img {
    display: none;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .tag-img {
    width: 10rem !important;
    top: 5rem !important;
    right: 2rem !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1920px) {
  .tag-img {
    width: 10rem !important;
    right: 3rem !important;
  }
}/*# sourceMappingURL=HomeSection.css.map */