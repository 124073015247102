@import '../../sass/variables';

.body-thanks-container {
    background-image: url('https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/bgs/thanks-bg.webp');
    width: 100%;
    height: 100%;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .deco-img-container {
        position: absolute;
        width: 100%;
        top: 15%;
    }
    .info-thanks-container {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .info-thanks-subcontainer {
            margin-top: 4.3rem;
            z-index: 1;
        }
        .info-btns-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            column-gap: 10%;
        }
        .thnks-btn-container {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            column-gap: 1rem;
            &:link {
                color: $basicColorSecondary;
                text-decoration: none;
            }
            &:visited {
                color: $basicColorSecondary;
            }
            &:active {
                color: $basicColorSecondary;
            }
        }
        .btn-thanks {
            cursor: pointer;
        }
        .thnk-subtitle-container{
            color: $basicColorPrimary;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .vt-tour-advise-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 8rem;
        }
        .common-areas-btns-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 3rem 0 10% 0;
        }
    }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
    .deco-img-container {
        display: none !important;
    }
    .info-thanks-subcontainer {
        margin-top: 11.5% !important;
        .thanks-sign-img {
            width: 90%;
            margin-left: 5%;
        }
        .info-btns-container {
            width: 90% !important;
            margin-left: 5%;
            .btn-thanks {
                width: 4rem;
            }
            .thnk-subtitle-container {
                font-size: 75% !important;
            }
        }
        .vt-tour-advise-container {
            margin-top: 3rem !important;
        }
        .vt-tour-advise-img {
            width: 5rem;
        }
        .common-areas-btns-container {
            width: 90% !important;
            margin-left: 5%;
            .btn-common-areas {
                width: 8rem;
            }
        }
    }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
    .deco-img-container {
        display: none !important;
    }
    .info-thanks-subcontainer {
        margin-top: 3.7rem !important;
        .thanks-sign-img {
            width: 90%;
            margin-left: 5%;
        }
        .info-btns-container {
            width: 90% !important;
            margin-left: 5%;
            .btn-thanks {
                width: 6rem;
            }
        }
        .vt-tour-advise-img {
            width: 7rem;
        }
        .common-areas-btns-container {
            width: 90% !important;
            margin-left: 5% !important;
            margin-top: 6rem !important;
            .btn-common-areas {
                width: 12rem;
            }
        }
    }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
    .deco-img-container {
        display: none !important;
    }
    .info-thanks-subcontainer {
        margin-top: 9% !important;
    }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
    .deco-img-container {
        transform: scale(0.55);
        top: 0% !important;
        left: -15%;
    }
    .info-thanks-subcontainer {
        margin-top: 2.8rem !important;
        .info-btns-container {
            width: 90% !important;
            margin-left: 5%;
            margin-top: 3rem !important;
            .btn-thanks {
                width: 6rem;
            }
        }
        .vt-tour-advise-container{
            margin-top: 3rem !important;
            .vt-tour-advise-img {
                width: 6rem;
            }
        }
        .common-areas-btns-container {
            margin-top: 2rem !important;
            .btn-common-areas {
                width: 12rem;
            }
        }
    }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
    .deco-img-container {
        transform: scale(0.65);
        left: -10%;
    }
    .info-thanks-subcontainer {
        margin-top: 3.8rem !important;
        .vt-tour-advise-container{
            margin-top: 3rem !important;
        }
    }
}