@import "../../sass/variables";

.funding-section {
  z-index: 1;
  bottom: -50%;
  left: -0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
  .funding-info-supercontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 5rem;
    row-gap: 2rem;
  }
  .popup-element-container {
    display: none;
    width: 100%;
    height: 40%;
    position: absolute;
    background-color: $basicColorPrimary;
    z-index: 10;
    border: 1px solid $buttonColorPrimary;
    border-radius: 0.5rem;
    .popup-subcontainer {
      margin: 0 1rem 1rem 1rem;
      overflow-y: scroll;
      height: 82%;
      position: relative;
    }
    .subtitle-funding-popup {
      font-family: $clanProUltraFont;
      color: $buttonColorPrimary;
      font-size: 1rem;
    }
    .title-table-popup {
      font-family: $clanProBoldFont;
      color: $subtitleColor;
      font-size: 0.9rem;
    }
    .funding-subcontainer-popup {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
    }
    .text-funding-popup {
      font-family: $sarabunLightFont;
      font-size: 0.85rem;
    }
    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    .close-popup-btn {
      cursor: pointer;
      margin: 0.5rem 0rem 0.5rem 1rem;
      font-size: 1.2rem;
      color: $buttonColorPrimary;
      width: 2rem;
    }
  }
  .funding-info-container {
    display: flex;
    width: 90%;
    position: relative;
    margin: 9rem 0 0 12%;
    .funding-title {
      margin-left: 10%;
      font-family: $playFairFont;
      color: $basicColorPrimary;
      position: absolute;
      font-size: 1.5rem;
      top: -4rem;
    }
    .title-subtext {
      font-family: $astralSistersFont;
      color: $subtitleColor;
      font-size: 2rem;
      font-weight: 200;
    }
    .imgs-container {
      width: 100%;
      position: relative;
      right: 2rem;
      top: 3rem;
    }
    .deco-img-funding {
      position: absolute;
      &.first {
        transform: scaleX(0.8);
        left: 60%;
        z-index: -1;
      }
    }
    .img-main-info {
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    .funding-text-container {
      width: 50%;
      position: relative;
      top: -4.2rem;
    }
    .subtitle-funding {
      font-family: $clanProUltraFont;
      color: $buttonColorPrimary;
    }
    .text-funding {
      font-family: $sarabunLightFont;
    }
    .funding-tbl-container {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin-left: 20%;
    }
    .funding-tbl-subcontainer {
      display: flex;
    }
    .title-table {
      width: 100%;
      font-family: $clanProBoldFont;
      color: $subtitleColor;
    }
    .value-fund-title {
      margin-bottom: 0.5rem;
    }
    .font-color {
      font-family: $sarabunMediumFont;
      color: $mainColorSecondary;
      font-size: 0.9rem;
    }
    .line-side-top {
      width: 50%;
      border-top: 1px dotted #000000;
      border-left: 1px dotted #000000;
      border-bottom: 1px dotted #000000;
      &.right {
        border-right: 1px dotted #000000;
      }
    }
    .line-side-left {
      border-left: 1px dotted #000000;
      &.right {
        border-right: 1px dotted #000000;
      }
    }
    .icon-text-container {
      position: absolute;
      right: 0;
      width: 29rem;
    }
    .icon-text-subcontainer {
      display: flex;
    }
    .moved-txt {
      margin-left: 4.5rem;
    }
    .deco-img-funding-text {
      width: 3rem;
      height: 3rem;
    }
    .funding-info-btn {
      margin-right: 0.5rem;
      width: 6.5rem;
      height: 2rem;
      cursor: pointer;
      border-radius: 0.2rem;
      background-color: $buttonColorPrimary;
      border: 1px solid $buttonColorPrimary;
      color: $basicColorPrimary;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      background-position: center;
      transition: background 0.8s;
      font-family: $clanProRgNewsFont;
      font-size: 0.8rem;
      &:hover {
        background: $buttonColorPrimaryHover
          radial-gradient(circle, transparent 1%, $buttonColorPrimaryHover 1%)
          center/15000%;
        border: 1px solid $buttonColorPrimaryHover;
      }
      &:active {
        background-color: $buttonColorPrimaryWave;
        background-size: 100%;
        transition: background 0s;
      }
    }
  }
  .show-more-btn-funding-info {
    margin: 0 5% 2rem 5%;
    border: 1px solid $buttonColorPrimary;
    border-radius: 0.5rem;
    max-height: 50rem;
    overflow: hidden;
    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    .smbtn-subcontainer {
      padding: 1rem;
      background-color: $basicColorPrimary;
    }
    .subtitle-funding-smbtn {
      font-family: $clanProUltraFont;
      color: $buttonColorPrimary;
      font-size: 1rem;
    }
    .title-table-smbtn {
      font-family: $clanProBoldFont;
      color: $subtitleColor;
      font-size: 0.9rem;
    }
    .funding-subcontainer-smbtn {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
    }
    .text-funding-smbtn {
      font-family: $sarabunLightFont;
      font-size: 0.85rem;
    }
  }
  .smbtn-deployed {
    @keyframes deployed {
      0% {
        max-height: 0rem;
      }
      100% {
        max-height: 50rem;
      }
    }
    animation-name: deployed;
    animation-duration: 1s;
    max-height: 50rem;
  }
  .smbtn-hide {
    @keyframes hideInfo {
      0% {
        max-height: 50rem;
      }
      100% {
        max-height: 0rem;
      }
    }
    animation-name: hideInfo;
    animation-duration: 1s;
    max-height: 0rem;
  }
  .changed-position {
    display: flex;
    align-items: flex-start;
    margin-left: 5% !important;
  }
  .form-deployed-container {
    z-index: 2;
  }
  .scale {
    transform: scaleY(0.8);
  }
  .show-funding {
    display: unset;
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .funding-section {
    margin: 0;
    position: relative;
    height: 34rem;
    .funding-bg-img {
      opacity: 0;
    }
    .funding-info-supercontainer {
      width: 100% !important;
      margin-top: 1rem;
    }
    .form-deployed-container {
      display: unset;
      margin: 0;
      width: 100%;
    }
    .funding-title {
      margin-left: 0 !important;
      width: 100% !important;
    }
    .funding-text-container {
      display: none;
    }
    .imgs-container {
      margin-top: 3rem;
      height: 18rem;
    }
    .deco-img-funding {
      display: none;
    }
    .img-main-info {
      position: absolute;
      transform: scale(0.5);
      bottom: -9%;
    }
  }
}

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .funding-section {
    margin: 0;
    position: relative;
    min-height: 39rem;
    .funding-bg-img {
      display: none;
    }
    .funding-info-supercontainer {
      width: 100% !important;
    }
    .form-deployed-container {
      display: unset;
      margin: 0;
      width: 100%;
    }
    .funding-title {
      margin-left: 0 !important;
      width: 100% !important;
    }
    .funding-text-container {
      display: none;
    }
    .imgs-container {
      margin-top: 3rem;
      height: 18rem;
    }
    .deco-img-funding {
      display: none;
    }
    .img-main-info {
      position: absolute;
      transform: scale(0.5);
      bottom: -9%;
    }
  }
}

/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .funding-section {
    margin: 0;
    position: relative;
    height: 100%;
    .funding-info-supercontainer {
      width: 100% !important;
    }
    .form-deployed-container {
      display: unset;
      margin: 0;
      width: 100%;
    }
    .funding-title {
      margin-left: 0 !important;
      width: 100% !important;
    }
    .funding-text-container {
      display: none;
    }
    .imgs-container {
      margin-top: 3rem;
      height: 18rem;
    }
    .deco-img-funding {
      display: none;
    }
    .img-main-info {
      position: absolute;
      transform: scale(0.5);
      bottom: -9%;
    }
  }
}