@import '../../sass/variables';

.header-thanks-container {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    background-color: $basicColorPrimary;
    .header-logo {
        width: 15rem;
        height: 100%;
    }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
    .header-logo {
        transform: scale(0.6);
    }
}