@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.navbar {
  display: flex;
  justify-content: center;
  height: 4rem;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
}
.navbar .navbar-subcontainer {
  width: 1024px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .logo-container {
  display: flex;
  align-items: center;
  margin-left: 3rem;
}
.navbar .logo-container .logo-img {
  height: 100%;
  width: 9rem;
}
.navbar .ham-menu-container {
  display: none;
  margin-right: 3rem;
}
.navbar .ham-menu-icon {
  height: 1rem;
  width: 1rem;
}
.navbar .menu-options-container {
  width: 10rem;
  height: 11rem;
  background-color: #ffffff;
  z-index: 100;
  position: fixed;
  top: 3.9rem;
  right: 0;
  padding: 1rem 2rem;
  transition: all 2s;
  font-family: SarabunLight;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.navbar .anchor {
  color: #000000 !important;
}
.navbar .anchor:link {
  color: #000000;
  text-decoration: none;
}
.navbar .anchor:visited {
  color: #000000;
}
.navbar .anchor:active {
  color: #000000;
}
.navbar .underline:hover {
  border-bottom: 1px solid #000000;
  border-bottom-width: 2px;
}
.navbar .navbar-menu {
  margin-bottom: 1rem;
}
.navbar .menus-container {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  align-items: center;
  font-family: SarabunLight;
  margin-right: 3rem;
}
.navbar .menus-container .anchor-container {
  margin-left: 2rem;
}
.navbar .menus-container .decorated {
  border: 1px solid #ffffff;
  border-radius: 0.8rem;
  padding: 0 1rem;
  height: 1.5rem;
  background-color: #ffffff;
  background-position: center;
  transition: background 0.8s;
}
.navbar .menus-container .decorated:hover {
  background: #312d29 radial-gradient(circle, transparent 1%, #312d29 1%) center/15000%;
}
.navbar .menus-container .decorated:active {
  background-color: #ccb697;
  background-size: 100%;
  transition: background 0s;
}
.navbar .menus-container .decorated .anchor {
  color: #ffffff;
}
.navbar .closed-menu {
  animation: openMenu 2s ease-in;
  right: -20rem;
}
@keyframes close {
  0% {
    right: 0rem;
  }
  100% {
    right: -20rem;
  }
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .navbar {
    align-items: center;
  }
  .navbar .navbar-subcontainer {
    width: 100%;
    justify-content: space-between;
  }
  .navbar .logo-container {
    margin-left: 0rem;
  }
  .navbar .logo-img {
    transform: scale(0.8);
  }
  .navbar .menus-container {
    display: none !important;
  }
  .navbar .ham-menu-container {
    display: unset !important;
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.2rem;
    margin-right: 1rem;
    cursor: pointer;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .navbar {
    align-items: center;
  }
  .navbar .navbar-subcontainer {
    width: 100%;
    justify-content: space-between;
  }
  .navbar .logo-container {
    margin-left: 1rem;
  }
  .navbar .logo-img {
    transform: scale(0.8);
  }
  .navbar .menus-container {
    display: none !important;
  }
  .navbar .ham-menu-container {
    display: unset !important;
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.2rem;
    cursor: pointer;
  }
}
/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar {
    align-items: center;
  }
  .navbar .navbar-subcontainer {
    width: 100%;
    justify-content: space-between;
  }
  .navbar .logo-container {
    margin-left: 1rem;
  }
  .navbar .logo-img {
    transform: scale(0.8);
  }
  .navbar .menus-container {
    display: none !important;
  }
  .navbar .ham-menu-container {
    display: unset !important;
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.2rem;
    cursor: pointer;
  }
}
/*Responsive Tablet Standard*/
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .navbar {
    align-items: center;
  }
  .navbar .menu-options-container {
    display: none;
  }
  .navbar .decorated {
    font-size: 0.95rem;
  }
}
/*Responsive Screen Small*/
@media screen and (min-width: 1200px) {
  .navbar .menu-options-container {
    display: none;
  }
}/*# sourceMappingURL=Navbar.css.map */