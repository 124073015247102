@import "../../sass/variables";

.form-container {
  display: flex;
  position: fixed;
  top: 15%;
  z-index: 15;
  right: -25rem;
  width: 46rem;
  .label-form {
    width: 20rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    .label-head-text {
      border: 2px solid $buttonColorPrimary;
      border-radius: 0px 0px 31px 31px;
      background-color: $buttonColorPrimary;
      color: $basicColorPrimary;
      transform: rotate(90deg);
      position: relative;
      top: 0;
      left: -8.5rem;
      width: 20rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      font-family: $clanProMediuFont;
      background-position: center;
      transition: background 0.8s;
      &:hover {
        background: $buttonColorPrimaryHover
          radial-gradient(circle, transparent 1%, $buttonColorPrimaryHover 1%)
          center/15000%;
      }
      &:active {
        background-color: $buttonColorPrimaryWave;
        background-size: 100%;
        transition: background 0s;
      }
    }
  }
  .form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1.5rem;
    background-color: $basicColorPrimary;
    border-radius: 1rem;
    position: relative;
    left: -17.5rem;
    .anchor-check {
      color: $basicColorSecondary;
      &:link {
        color: $basicColorSecondary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorSecondary;
      }
      &:active {
        color: $basicColorSecondary;
      }
    }
    .form-input-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1.5rem 1.5rem 0 0;
    }
    .names-container {
      display: flex;
      column-gap: 2rem;
    }
    .form-title {
      font-size: 1.3rem;
      font-family: $clanProMediuFont;
    }
    label {
      width: 100%;
    }
    .input {
      width: 100%;
      border-radius: 0.3rem;
      height: 2.5rem;
      padding-left: 1.2rem;
      border: 1px solid $buttonColorPrimary;
      outline: none;
      font-family: $sarabunLightFont;
    }
    .select {
      width: 109%;
    }
    .mandatory-text {
      font-size: 0.8rem;
      color: $errorMsgColor;
      font-family: $sarabunLightFont;
    }
    .hide {
      opacity: 0;
    }
    .label-advise {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-family: $sarabunLightItalicFont;
    }
    .error {
      color: $errorMsgColor;
    }
    .checkbox-input {
      margin-right: 0.5rem;
    }
    .submit-form {
      width: 100%;
      height: 2rem;
      cursor: pointer;
      border: 1px solid $buttonColorPrimary;
      background-color: $buttonColorPrimary;
      color: $basicColorPrimary;
      border-radius: 0.2rem;
      background-position: center;
      transition: background 0.8s;
      font-family: $clanProRgNewsFont;
      &:hover {
        background: $buttonColorPrimaryHover
          radial-gradient(circle, transparent 1%, $buttonColorPrimaryHover 1%)
          center/15000%;
      }
      &:active {
        background-color: $buttonColorPrimaryWave;
        background-size: 100%;
        transition: background 0s;
      }
    }
  }
}

.closed {
  @keyframes close {
    0% {
      right: -22.5rem;
    }
    100% {
      right: -43.7rem;
    }
  }
  animation-name: close;
  animation-duration: 1s;
  right: -43.7rem;
}

.open {
  @keyframes openForm {
    0% {
      right: -43.7rem;
    }
    100% {
      right: -22.5rem;
    }
  }
  animation-name: openForm;
  animation-duration: 1s;
  right: -22.5rem;
}

.deployed {
  position: unset;
  width: 100%;
  display: unset;
}

.hiden-label {
  display: none !important;
}

.form-deployed {
  position: unset !important;
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .form-container {
    position: unset !important;
    width: 100%;
    justify-content: center;
    .label-form {
      display: none;
    }
    .form-title {
      font-size: 1.1rem !important;
    }
    .names-container {
      flex-direction: column !important;
      width: 100%;
    }
    label {
      margin-bottom: 0 !important;
    }
    .form-input-container {
      width: 100%;
      margin: 1.25rem 0 0 0 !important;
    }
    .input {
      width: 91% !important;
    }
    .form-content {
      position: unset;
      width: 100%;
      border-radius: unset;
      height: 100% !important;
    }
    .submit-form {
      margin-top: 2rem;
    }
  }
}

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .form-container {
    position: static;
    width: 100%;
    justify-content: center;
    .label-form {
      display: none;
    }
    .form-title {
      font-size: 1.1rem !important;
    }
    .names-container {
      flex-direction: column !important;
      width: 100%;
    }
    label {
      margin-bottom: 0 !important;
    }
    .form-input-container {
      width: 100%;
    }
    .form-content {
      position: unset;
      height: 100% !important;
      width: 100%;
      border-radius: unset;
    }
    .submit-form {
      margin-top: 2rem;
    }
  }
}

/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .form-container {
    position: unset !important;
    width: 100%;
    justify-content: center;
    .label-form {
      display: none;
    }
    .form-title {
      font-size: 1.1rem !important;
    }
    label {
      margin-bottom: 0 !important;
    }
    .form-input-container {
      width: 100%;
    }
    .form-content {
      position: unset;
      height: 100% !important;
    }
    .submit-form {
      margin-top: 2rem;
    }
  }
}

/*Responsive Tablet Standard*/

@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .form-title {
    font-size: 1.1rem !important;
  }
}

/*Responsive Small Screen*/

@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .form-container {
    .form-content {
      // max-height: 35.5rem !important;
      height: unset;
    }
    .form-deployed {
      width: 90% !important;
    }
  }
}

//Responsive HD+ Screen
@media screen and (min-width: $screenHd) {
  .form-deployed {
    width: 90% !important;
  }
}
