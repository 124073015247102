@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.apto-section {
  z-index: 1;
  bottom: -50%;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
}
.apto-section .apto-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 5rem;
  row-gap: 2rem;
}
.apto-section .apto-title-container {
  width: 100%;
}
.apto-section .apto-title-container .apto-title {
  margin-left: 15%;
  font-size: 2rem;
  font-weight: lighter;
  color: #5c5c66;
  font-style: italic;
}
.apto-section .apto-info-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.apto-section .apto-info-container .apto-gallery-container {
  width: 40rem;
  height: 25rem;
}
.apto-section .apto-info-container .area-info-container {
  margin-bottom: 1rem;
  color: #5c5c66;
}
.apto-section .apto-info-container .info-text {
  font-size: 1.2rem;
  color: #5c5c66;
  font-weight: bold;
}
.apto-section .apto-info-container .info-text-container {
  color: #5c5c66;
}
.apto-section .apto-info-container .btn-view-apto {
  padding: 0.5rem 1.5rem;
  width: 100%;
  cursor: pointer;
  border: 1px solid #5c5c66;
  color: #ffffff;
  background-color: #5c5c66;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  background-position: center;
  transition: background 0.8s;
  font-family: ClanProRgNews;
  margin-top: 2rem;
  font-style: italic;
}
.apto-section .apto-info-container .btn-view-apto:link {
  color: #ffffff;
  text-decoration: none;
}
.apto-section .apto-info-container .btn-view-apto:visited {
  color: #ffffff;
}
.apto-section .apto-info-container .btn-view-apto:hover {
  background: #ffffff radial-gradient(circle, transparent 1%, #ffffff 1%) center/15000%;
  color: #5c5c66;
}
.apto-section .apto-info-container .btn-view-apto:active {
  background-color: #ffffff;
  background-size: 100%;
  transition: background 0s;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .apto-section {
    height: 45rem !important;
  }
  .as-bg-img {
    height: 45rem;
  }
  .apto-subcontainer {
    width: 100% !important;
    margin-top: 4rem !important;
  }
  .apto-subcontainer .apto-title {
    font-size: 1.2rem !important;
    text-align: center;
    margin-left: 0 !important;
  }
  .apto-subcontainer .apto-info-container {
    flex-direction: column;
    row-gap: 2rem;
  }
  .apto-subcontainer .apto-gallery-container {
    width: auto !important;
    height: 11rem !important;
  }
  .apto-subcontainer .btn-view-apto {
    width: 80% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .apto-section {
    height: 45rem !important;
  }
  .as-bg-img {
    height: 45rem;
  }
  .apto-subcontainer {
    width: 100% !important;
    margin-top: 4rem !important;
  }
  .apto-subcontainer .apto-title {
    font-size: 1.2rem !important;
    text-align: center;
    margin-left: 0 !important;
  }
  .apto-subcontainer .apto-info-container {
    flex-direction: column;
    row-gap: 2rem;
  }
  .apto-subcontainer .apto-info-subcontainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .apto-subcontainer .apto-gallery-container {
    width: auto !important;
    height: 11rem !important;
  }
  .apto-subcontainer .btn-view-apto {
    width: 80% !important;
    align-self: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .apto-subcontainer {
    width: 100% !important;
    margin-top: 4rem !important;
  }
  .apto-subcontainer .apto-info-container {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
    align-items: flex-end;
  }
}/*# sourceMappingURL=AptoSection.css.map */