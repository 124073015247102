@import "../../sass/variables";

.navbar {
  display: flex;
  justify-content: center;
  height: 4rem;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  .navbar-subcontainer {
    width: 1024px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo-container {
    display: flex;
    align-items: center;
    margin-left: 3rem;
    .logo-img {
      height: 100%;
      width: 9rem;
    }
  }
  .ham-menu-container {
    display: none;
    margin-right: 3rem;
  }
  .ham-menu-icon {
    height: 1rem;
    width: 1rem;
  }
  .menu-options-container {
    width: 10rem;
    height: 11rem;
    background-color: $basicColorPrimary;
    z-index: 100;
    position: fixed;
    top: 3.9rem;
    right: 0;
    padding: 1rem 2rem;
    transition: all 2s;
    font-family: $sarabunLightFont;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .anchor {
    color: $basicColorSecondary !important;
    &:link {
      color: $basicColorSecondary;
      text-decoration: none;
    }
    &:visited {
      color: $basicColorSecondary;
    }
    &:active {
      color: $basicColorSecondary;
    }
  }
  .underline:hover {
    border-bottom: 1px solid $basicColorSecondary;
    border-bottom-width: 2px;
  }
  .navbar-menu {
    margin-bottom: 1rem;
  }
  .menus-container {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    align-items: center;
    font-family: $sarabunLightFont;
    margin-right: 3rem;
    .anchor-container {
      margin-left: 2rem;
    }
    .decorated {
      border: 1px solid $basicColorPrimary;
      border-radius: 0.8rem;
      padding: 0 1rem;
      height: 1.5rem;
      background-color: $basicColorPrimary;
      background-position: center;
      transition: background 0.8s;
      &:hover {
        background: $mainColorPrimaryHover
          radial-gradient(circle, transparent 1%, $mainColorPrimaryHover 1%)
          center/15000%;
      }
      &:active {
        background-color: $buttonColorSecondaryWave;
        background-size: 100%;
        transition: background 0s;
      }
      .anchor {
        color: $basicColorPrimary;
      }
    }
  }
  .closed-menu {
    @keyframes close {
      0% {
        right: 0rem;
      }
      100% {
        right: -20rem;
      }
    }
    animation: openMenu 2s ease-in;
    right: -20rem;
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .navbar {
    align-items: center;
    .navbar-subcontainer {
      width: 100%;
      justify-content: space-between;
    }
    .logo-container {
      margin-left: 0rem;
    }
    .logo-img {
      transform: scale(0.8);
    }
    .menus-container {
      display: none !important;
    }
    .ham-menu-container {
      display: unset !important;
      width: 1.2rem;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-right: 1rem;
      cursor: pointer;
    }
  }
}

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .navbar {
    align-items: center;
    .navbar-subcontainer {
      width: 100%;
      justify-content: space-between;
    }
    .logo-container {
      margin-left: 1rem;
    }
    .logo-img {
      transform: scale(0.8);
    }
    .menus-container {
      display: none !important;
    }
    .ham-menu-container {
      display: unset !important;
      width: 1.2rem;
      height: 1.2rem;
      margin-top: 0.2rem;
      cursor: pointer;
    }
  }
}

/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .navbar {
    align-items: center;
    .navbar-subcontainer {
      width: 100%;
      justify-content: space-between;
    }
    .logo-container {
      margin-left: 1rem;
    }
    .logo-img {
      transform: scale(0.8);
    }
    .menus-container {
      display: none !important;
    }
    .ham-menu-container {
      display: unset !important;
      width: 1.2rem;
      height: 1.2rem;
      margin-top: 0.2rem;
      cursor: pointer;
    }
  }
}

/*Responsive Tablet Standard*/

@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .navbar {
    align-items: center;
    .menu-options-container {
      display: none;
    }
    .decorated {
      font-size: 0.95rem;
    }
  }
}

/*Responsive Screen Small*/

@media screen and (min-width: $screenSm) {
  .navbar {
    .menu-options-container {
      display: none;
    }
  }
}
