@import "../../sass/variables";

.apto-section {
  z-index: 1;
  bottom: -50%;
  left: -0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
  .apto-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 5rem;
    row-gap: 2rem;
  }
  .apto-title-container {
    width: 100%;
    .apto-title {
      margin-left: 15%;
      font-size: 2rem;
      font-weight: lighter;
      color: $mainColorPrimary;
      font-style: italic;
    }
  }
  .apto-info-container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    height: 100%;
    column-gap: 1rem;
    .apto-gallery-container {
      width: 40rem;
      height: 25rem;
    }
    .area-info-container {
      margin-bottom: 1rem;
      color: $mainColorPrimary;
    }
    .info-text {
      font-size: 1.2rem;
      color: $mainColorPrimary;
      font-weight: bold;
    }
    .info-text-container {
      color: $mainColorPrimary;
    }
    .btn-view-apto {
      padding: 0.5rem 1.5rem;
      width: 100%;
      cursor: pointer;
      border: 1px solid $mainColorPrimary;
      color: $basicColorPrimary;
      background-color: $mainColorPrimary;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2rem;
      background-position: center;
      transition: background 0.8s;
      font-family: $clanProRgNewsFont;
      margin-top: 2rem;
      font-style: italic;
      &:link {
        color: $basicColorPrimary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorPrimary;
      }
      &:hover {
        background: $basicColorPrimary
          radial-gradient(circle, transparent 1%, $basicColorPrimary 1%)
          center/15000%;
        color: $mainColorPrimary;
      }
      &:active {
        background-color: $basicColorPrimary;
        background-size: 100%;
        transition: background 0s;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .apto-section {
    height: 45rem !important;
  }
  .as-bg-img {
    height: 45rem;
  }
  .apto-subcontainer {
    width: 100% !important;
    margin-top: 4rem !important;
    .apto-title {
      font-size: 1.2rem !important;
      text-align: center;
      margin-left: 0 !important;
    }
    .apto-info-container {
      flex-direction: column;
      row-gap: 2rem;
    }
    .apto-gallery-container {
      width: auto !important;
      height: 11rem !important;
    }
    .btn-view-apto {
      width: 80% !important;
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .apto-section {
    height: 45rem !important;
  }
  .as-bg-img {
    height: 45rem;
  }
  .apto-subcontainer {
    width: 100% !important;
    margin-top: 4rem !important;
    .apto-title {
      font-size: 1.2rem !important;
      text-align: center;
      margin-left: 0 !important;
    }
    .apto-info-container {
      flex-direction: column;
      row-gap: 2rem;
    }
    .apto-info-subcontainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .apto-gallery-container {
      width: auto !important;
      height: 11rem !important;
    }
    .btn-view-apto {
      width: 80% !important;
      align-self: center;
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .apto-subcontainer {
    width: 100% !important;
    margin-top: 4rem !important;
    .apto-info-container {
      column-gap: 2rem;
      align-items: flex-end;
    }
  }
}
