@import "../../sass/variables";

.form-wa-container {
  position: fixed;
  bottom: 7rem;
  right: -20rem;
  opacity: 0;
  z-index: 99;
  background-color: $basicColorPrimary;
  padding: 1rem;
  // border: 2px solid $borderWhatsappForm;
  border-radius: 0.5rem;
  .form-wa-input-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem;
    cursor: pointer;
  }
  label {
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem;
    outline: none;
    .input-form-wa {
      height: 1.5rem;
      border: 1px solid $borderWhatsappForm;
      border-radius: 0.2rem;
      padding-left: 1rem;
    }
  }
  .label-advise {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: $sarabunLightItalicFont;
    flex-direction: row;
    column-gap: 0.5rem;
  }
  .anchor-check {
    color: $basicColorSecondary;
    &:link {
      color: $basicColorSecondary;
      text-decoration: none;
    }
    &:visited {
      color: $basicColorSecondary;
    }
    &:active {
      color: $basicColorSecondary;
    }
  }
  .form-wa-btn {
    height: 1.5rem;
    border: 1px solid $whatsappBtnColor;
    background-color: $whatsappBtnColor;
    color: $basicColorPrimary;
    border-radius: 0.2rem;
    background-position: center;
    transition: background 0.8s;
    font-family: $clanProRgNewsFont;
    display: flex;
    justify-content: center;
    padding: 0.25rem 0;
    &:link {
      color: $basicColorPrimary;
      text-decoration: none;
    }
    &:visited {
      color: $basicColorPrimary;
    }
    &:hover {
      background-color: $whatsappBtnColorHover;
    }
    &:active {
      color: $basicColorPrimary;
    }
  }
  .mandatory-text {
    font-size: 0.8rem;
    color: $errorMsgColor;
    font-family: $sarabunLightFont;
  }
  .hide {
    opacity: 0;
  }
}
.open-wa-form {
  @keyframes openWaForm {
    0% {
      right: -20rem;
      opacity: 0;
    }
    100% {
      right: 1.2rem;
      opacity: 1;
    }
  }
  animation-name: openWaForm;
  animation-duration: 1s;
  right: 1.2rem;
  opacity: 1;
}

.close-wa-form {
  @keyframes closeWaForm {
    0% {
      opacity: 0;
      right: 1.2rem;
    }
    100% {
      right: -20rem;
      opacity: 0;
    }
  }
  animation-name: closeWaForm;
  animation-duration: 1s;
  opacity: 0;
  right: -20rem;
}

.wa-btn-container {
  height: 4rem;
  display: flex;
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  z-index: 100;
  align-items: center;
  .wa-btn-icon-container {
    width: 3rem;
    .wa-icon {
      width: 100%;
      height: 100%;
    }
  }
  .wa-hover-element {
    width: 70%;
    padding: 0.2rem 1rem;
    background-color: $whatsappBtnColor;
    color: $basicColorPrimary;
    border-radius: 0.5rem;
    font-family: $sarabunLightFont;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: absolute;
    left: 40%;
    z-index: -1;
    opacity: 0;
  }
  .show {
    @keyframes showWATitle {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation-name: showWATitle;
    animation-duration: 1s;
    opacity: 1;
  }
  .hide {
    @keyframes hide {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    animation-name: hide;
    animation-duration: 1s;
    opacity: 0;
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .wa-btn-container {
    bottom: 1rem;
    .wa-btn-icon-container {
      width: 3rem !important;
    }
    .wa-hover-element {
      left: 35%;
    }
  }
}
