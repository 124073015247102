@import "../../sass/variables";

.banner-section {
  width: 1920px;
  margin: 0 auto;
  overflow: hidden;
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($tabletSm - 1px)) {
  .banner-section {
    width: 767px;
  }
}