@import "../../sass/variables";

.common-areas-section {
  z-index: 1;
  bottom: -50%;
  left: -0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
  .common-areas-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 3rem;
  }
  .title-container {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    .title {
      font-size: 1.3rem;
      font-weight: lighter;
      color: $mainColorPrimary;
      font-style: italic;
      background-color: $basicColorPrimary;
      padding: 0.25rem 1.5rem;
      border-radius: 2rem;
    }
  }
  .frame-area {
    width: 80%;
    height: 30rem;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    // .frame {
    //   width: 100%;
    //   height: 100%;
    //   position: relative;
    //   z-index: 1;
    // }
    .ca-gallery-container {
      height: 100%;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .common-areas-subcontainer {
    width: 100% !important;
  }
  .title {
    font-size: 1.2rem !important;
  }
  .frame-area {
    width: 90vw !important;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .common-areas-subcontainer {
    width: 100% !important;
  }
  .title {
    font-size: 1.2rem !important;
  }
  .frame-area {
    width: 90vw !important;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .common-areas-subcontainer {
    width: 100% !important;
  }
}