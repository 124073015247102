@import "../../sass/variables";

.gallery-container {
    display: flex;
    align-items: center;
    z-index: 2;
    .img-container {
        position: relative;
        .gallery-img {
            border-radius: 0.5rem;
            margin-right: 1rem;
            height: 18rem;
            width: 30rem;
        }
        .btns-container {
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            display: flex;
        }
        .btn-gallery {
            margin-right: 0.5rem;
            width: 7.5rem;
            height: 2rem;
            cursor: pointer;
            border-radius: 0.2rem;
            background-color: $basicColorPrimary;
            border: 1px solid $basicColorPrimary;
            color: $mainColorPrimary;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            background-position: center;
            font-family: $clanProRgNewsFont;
            font-size: 0.8rem;
            &:hover {
                background: $mainColorPrimary;
                border: 1px solid $mainColorPrimary;
                color: $basicColorPrimary;
            }
            &:active {
                background-color: $basicColorPrimary;
                background-size: 100%;
                transition: background 0s;
            }
        }
        .btn-icon {
            width: 1rem;
            height: 1rem;
            margin-right: 0.3rem;
        }
    }
    .thumbs-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 18.5rem;
        column-gap: 1rem;
        row-gap: 1rem;
        padding-top: 0.3rem;
        .thumb {
            cursor: pointer;
        }
        .last {
            position: relative;
        }
        .more-btn-container {
            position: absolute;
            background-color: $buttonColorPrimaryOpa;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 0.5rem;
        }
        .img-total {
            color: $basicColorPrimary;
            font-size: 1.3rem;
            margin-bottom: 0.2rem;
            font-family: $clanProRgNewsFont;
        }
        .more-btn {
            width: 70%;
            height: 2rem;
            cursor: pointer;
            background-color: $basicColorPrimary;
            border: $basicColorPrimary;
            border-radius: 0.5rem;
            color: $mainColorPrimary;
            background-position: center;
            font-family: $clanProRgNewsFont;
            &:hover {
                background-color: $mainColorPrimary;
                border-color: $mainColorPrimary;
                color: $basicColorPrimary;
            }
            &:active {
                background-color: $buttonColorPrimaryWave;
                background-size: 100%;
                transition: background 0s;
            }
        }
        .thumb-img {
            width: 15rem;
            height: 8.5rem;
            border-radius: 0.5rem;
        }
    }
}


/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
    .thumbs-container {
        grid-template-columns: 1fr !important;
    }
  }
