@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.gallery-container {
  display: flex;
  align-items: center;
  z-index: 2;
}
.gallery-container .img-container {
  position: relative;
}
.gallery-container .img-container .gallery-img {
  border-radius: 0.5rem;
  margin-right: 1rem;
  height: 18rem;
  width: 30rem;
}
.gallery-container .img-container .btns-container {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
}
.gallery-container .img-container .btn-gallery {
  margin-right: 0.5rem;
  width: 7.5rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 0.2rem;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #5c5c66;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-position: center;
  font-family: ClanProRgNews;
  font-size: 0.8rem;
}
.gallery-container .img-container .btn-gallery:hover {
  background: #5c5c66;
  border: 1px solid #5c5c66;
  color: #ffffff;
}
.gallery-container .img-container .btn-gallery:active {
  background-color: #ffffff;
  background-size: 100%;
  transition: background 0s;
}
.gallery-container .img-container .btn-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
}
.gallery-container .thumbs-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 18.5rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  row-gap: 1rem;
  padding-top: 0.3rem;
}
.gallery-container .thumbs-container .thumb {
  cursor: pointer;
}
.gallery-container .thumbs-container .last {
  position: relative;
}
.gallery-container .thumbs-container .more-btn-container {
  position: absolute;
  background-color: rgba(108, 179, 1, 0.1764705882);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.5rem;
}
.gallery-container .thumbs-container .img-total {
  color: #ffffff;
  font-size: 1.3rem;
  margin-bottom: 0.2rem;
  font-family: ClanProRgNews;
}
.gallery-container .thumbs-container .more-btn {
  width: 70%;
  height: 2rem;
  cursor: pointer;
  background-color: #ffffff;
  border: #ffffff;
  border-radius: 0.5rem;
  color: #5c5c66;
  background-position: center;
  font-family: ClanProRgNews;
}
.gallery-container .thumbs-container .more-btn:hover {
  background-color: #5c5c66;
  border-color: #5c5c66;
  color: #ffffff;
}
.gallery-container .thumbs-container .more-btn:active {
  background-color: #e2d3bc;
  background-size: 100%;
  transition: background 0s;
}
.gallery-container .thumbs-container .thumb-img {
  width: 15rem;
  height: 8.5rem;
  border-radius: 0.5rem;
}

/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .thumbs-container {
    grid-template-columns: 1fr !important;
  }
}/*# sourceMappingURL=GalleryHorizontal.css.map */