@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.body-thanks-container {
  background-image: url("https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/bgs/thanks-bg.webp");
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.body-thanks-container .deco-img-container {
  position: absolute;
  width: 100%;
  top: 15%;
}
.body-thanks-container .info-thanks-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.body-thanks-container .info-thanks-container .info-thanks-subcontainer {
  margin-top: 4.3rem;
  z-index: 1;
}
.body-thanks-container .info-thanks-container .info-btns-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  -moz-column-gap: 10%;
       column-gap: 10%;
}
.body-thanks-container .info-thanks-container .thnks-btn-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.body-thanks-container .info-thanks-container .thnks-btn-container:link {
  color: #000000;
  text-decoration: none;
}
.body-thanks-container .info-thanks-container .thnks-btn-container:visited {
  color: #000000;
}
.body-thanks-container .info-thanks-container .thnks-btn-container:active {
  color: #000000;
}
.body-thanks-container .info-thanks-container .btn-thanks {
  cursor: pointer;
}
.body-thanks-container .info-thanks-container .thnk-subtitle-container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body-thanks-container .info-thanks-container .vt-tour-advise-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}
.body-thanks-container .info-thanks-container .common-areas-btns-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 3rem 0 10% 0;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .deco-img-container {
    display: none !important;
  }
  .info-thanks-subcontainer {
    margin-top: 11.5% !important;
  }
  .info-thanks-subcontainer .thanks-sign-img {
    width: 90%;
    margin-left: 5%;
  }
  .info-thanks-subcontainer .info-btns-container {
    width: 90% !important;
    margin-left: 5%;
  }
  .info-thanks-subcontainer .info-btns-container .btn-thanks {
    width: 4rem;
  }
  .info-thanks-subcontainer .info-btns-container .thnk-subtitle-container {
    font-size: 75% !important;
  }
  .info-thanks-subcontainer .vt-tour-advise-container {
    margin-top: 3rem !important;
  }
  .info-thanks-subcontainer .vt-tour-advise-img {
    width: 5rem;
  }
  .info-thanks-subcontainer .common-areas-btns-container {
    width: 90% !important;
    margin-left: 5%;
  }
  .info-thanks-subcontainer .common-areas-btns-container .btn-common-areas {
    width: 8rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .deco-img-container {
    display: none !important;
  }
  .info-thanks-subcontainer {
    margin-top: 3.7rem !important;
  }
  .info-thanks-subcontainer .thanks-sign-img {
    width: 90%;
    margin-left: 5%;
  }
  .info-thanks-subcontainer .info-btns-container {
    width: 90% !important;
    margin-left: 5%;
  }
  .info-thanks-subcontainer .info-btns-container .btn-thanks {
    width: 6rem;
  }
  .info-thanks-subcontainer .vt-tour-advise-img {
    width: 7rem;
  }
  .info-thanks-subcontainer .common-areas-btns-container {
    width: 90% !important;
    margin-left: 5% !important;
    margin-top: 6rem !important;
  }
  .info-thanks-subcontainer .common-areas-btns-container .btn-common-areas {
    width: 12rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .deco-img-container {
    display: none !important;
  }
  .info-thanks-subcontainer {
    margin-top: 9% !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .deco-img-container {
    transform: scale(0.55);
    top: 0% !important;
    left: -15%;
  }
  .info-thanks-subcontainer {
    margin-top: 2.8rem !important;
  }
  .info-thanks-subcontainer .info-btns-container {
    width: 90% !important;
    margin-left: 5%;
    margin-top: 3rem !important;
  }
  .info-thanks-subcontainer .info-btns-container .btn-thanks {
    width: 6rem;
  }
  .info-thanks-subcontainer .vt-tour-advise-container {
    margin-top: 3rem !important;
  }
  .info-thanks-subcontainer .vt-tour-advise-container .vt-tour-advise-img {
    width: 6rem;
  }
  .info-thanks-subcontainer .common-areas-btns-container {
    margin-top: 2rem !important;
  }
  .info-thanks-subcontainer .common-areas-btns-container .btn-common-areas {
    width: 12rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .deco-img-container {
    transform: scale(0.65);
    left: -10%;
  }
  .info-thanks-subcontainer {
    margin-top: 3.8rem !important;
  }
  .info-thanks-subcontainer .vt-tour-advise-container {
    margin-top: 3rem !important;
  }
}/*# sourceMappingURL=BodyThanksPage.css.map */