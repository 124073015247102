@import "../../sass/variables";

.footer-thanks-container {
  width: 100%;
  background-color: $bgFooterColorMain;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: center;
  height: 100%;
  .footer-thanks-logo-otacc {
    margin-left: 5%;
    width: 20rem;
    height: 100%;
  }
  .more-prjs-container {
    margin-right: 5%;
    display: flex;
    align-items: center;
    .prjs-logos {
      width: 15rem;
      margin-right: 2rem;
    }
    .show-more-btn {
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;
      outline: none;
      background-color: $buttonColorPrimary;
      color: white;
      cursor: pointer;
      border: 1px solid $buttonColorPrimary;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: white;
        color: $buttonColorPrimaryHover;
        border-color: white;
      }
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .footer-thanks-container {
    flex-direction: column;
    row-gap: 1rem;
    .footer-thanks-logo-otacc {
      width: 15rem;
    }
    .more-prjs-container {
      margin-right: 0;
      .prjs-logos {
        width: 8rem !important;
      }
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .footer-thanks-container {
    flex-direction: column;
    row-gap: 2rem;
    .more-prjs-container {
      margin-right: 0;
      .prjs-logos {
        width: 13rem !important;
      }
    }
  }
}
