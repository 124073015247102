@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.funding-section {
  z-index: 1;
  bottom: -50%;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
}
.funding-section .funding-info-supercontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 5rem;
  row-gap: 2rem;
}
.funding-section .popup-element-container {
  display: none;
  width: 100%;
  height: 40%;
  position: absolute;
  background-color: #ffffff;
  z-index: 10;
  border: 1px solid #6bb301;
  border-radius: 0.5rem;
}
.funding-section .popup-element-container .popup-subcontainer {
  margin: 0 1rem 1rem 1rem;
  overflow-y: scroll;
  height: 82%;
  position: relative;
}
.funding-section .popup-element-container .subtitle-funding-popup {
  font-family: ClanProUltra;
  color: #6bb301;
  font-size: 1rem;
}
.funding-section .popup-element-container .title-table-popup {
  font-family: ClanProBold;
  color: #ff6601;
  font-size: 0.9rem;
}
.funding-section .popup-element-container .funding-subcontainer-popup {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.funding-section .popup-element-container .text-funding-popup {
  font-family: SarabunLight;
  font-size: 0.85rem;
}
.funding-section .popup-element-container ::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.funding-section .popup-element-container .close-popup-btn {
  cursor: pointer;
  margin: 0.5rem 0rem 0.5rem 1rem;
  font-size: 1.2rem;
  color: #6bb301;
  width: 2rem;
}
.funding-section .funding-info-container {
  display: flex;
  width: 90%;
  position: relative;
  margin: 9rem 0 0 12%;
}
.funding-section .funding-info-container .funding-title {
  margin-left: 10%;
  font-family: "Playfair Display", serif;
  color: #ffffff;
  position: absolute;
  font-size: 1.5rem;
  top: -4rem;
}
.funding-section .funding-info-container .title-subtext {
  font-family: AstralSisters;
  color: #ff6601;
  font-size: 2rem;
  font-weight: 200;
}
.funding-section .funding-info-container .imgs-container {
  width: 100%;
  position: relative;
  right: 2rem;
  top: 3rem;
}
.funding-section .funding-info-container .deco-img-funding {
  position: absolute;
}
.funding-section .funding-info-container .deco-img-funding.first {
  transform: scaleX(0.8);
  left: 60%;
  z-index: -1;
}
.funding-section .funding-info-container .img-main-info {
  z-index: 1;
  width: 100%;
  height: 100%;
}
.funding-section .funding-info-container .funding-text-container {
  width: 50%;
  position: relative;
  top: -4.2rem;
}
.funding-section .funding-info-container .subtitle-funding {
  font-family: ClanProUltra;
  color: #6bb301;
}
.funding-section .funding-info-container .text-funding {
  font-family: SarabunLight;
}
.funding-section .funding-info-container .funding-tbl-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 20%;
}
.funding-section .funding-info-container .funding-tbl-subcontainer {
  display: flex;
}
.funding-section .funding-info-container .title-table {
  width: 100%;
  font-family: ClanProBold;
  color: #ff6601;
}
.funding-section .funding-info-container .value-fund-title {
  margin-bottom: 0.5rem;
}
.funding-section .funding-info-container .font-color {
  font-family: SarabunMedium;
  color: #243455;
  font-size: 0.9rem;
}
.funding-section .funding-info-container .line-side-top {
  width: 50%;
  border-top: 1px dotted #000000;
  border-left: 1px dotted #000000;
  border-bottom: 1px dotted #000000;
}
.funding-section .funding-info-container .line-side-top.right {
  border-right: 1px dotted #000000;
}
.funding-section .funding-info-container .line-side-left {
  border-left: 1px dotted #000000;
}
.funding-section .funding-info-container .line-side-left.right {
  border-right: 1px dotted #000000;
}
.funding-section .funding-info-container .icon-text-container {
  position: absolute;
  right: 0;
  width: 29rem;
}
.funding-section .funding-info-container .icon-text-subcontainer {
  display: flex;
}
.funding-section .funding-info-container .moved-txt {
  margin-left: 4.5rem;
}
.funding-section .funding-info-container .deco-img-funding-text {
  width: 3rem;
  height: 3rem;
}
.funding-section .funding-info-container .funding-info-btn {
  margin-right: 0.5rem;
  width: 6.5rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 0.2rem;
  background-color: #6bb301;
  border: 1px solid #6bb301;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-position: center;
  transition: background 0.8s;
  font-family: ClanProRgNews;
  font-size: 0.8rem;
}
.funding-section .funding-info-container .funding-info-btn:hover {
  background: #90da21 radial-gradient(circle, transparent 1%, #90da21 1%) center/15000%;
  border: 1px solid #90da21;
}
.funding-section .funding-info-container .funding-info-btn:active {
  background-color: #e2d3bc;
  background-size: 100%;
  transition: background 0s;
}
.funding-section .show-more-btn-funding-info {
  margin: 0 5% 2rem 5%;
  border: 1px solid #6bb301;
  border-radius: 0.5rem;
  max-height: 50rem;
  overflow: hidden;
}
.funding-section .show-more-btn-funding-info ::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.funding-section .show-more-btn-funding-info .smbtn-subcontainer {
  padding: 1rem;
  background-color: #ffffff;
}
.funding-section .show-more-btn-funding-info .subtitle-funding-smbtn {
  font-family: ClanProUltra;
  color: #6bb301;
  font-size: 1rem;
}
.funding-section .show-more-btn-funding-info .title-table-smbtn {
  font-family: ClanProBold;
  color: #ff6601;
  font-size: 0.9rem;
}
.funding-section .show-more-btn-funding-info .funding-subcontainer-smbtn {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.funding-section .show-more-btn-funding-info .text-funding-smbtn {
  font-family: SarabunLight;
  font-size: 0.85rem;
}
.funding-section .smbtn-deployed {
  animation-name: deployed;
  animation-duration: 1s;
  max-height: 50rem;
}
@keyframes deployed {
  0% {
    max-height: 0rem;
  }
  100% {
    max-height: 50rem;
  }
}
.funding-section .smbtn-hide {
  animation-name: hideInfo;
  animation-duration: 1s;
  max-height: 0rem;
}
@keyframes hideInfo {
  0% {
    max-height: 50rem;
  }
  100% {
    max-height: 0rem;
  }
}
.funding-section .changed-position {
  display: flex;
  align-items: flex-start;
  margin-left: 5% !important;
}
.funding-section .form-deployed-container {
  z-index: 2;
}
.funding-section .scale {
  transform: scaleY(0.8);
}
.funding-section .show-funding {
  display: unset;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .funding-section {
    margin: 0;
    position: relative;
    height: 34rem;
  }
  .funding-section .funding-bg-img {
    opacity: 0;
  }
  .funding-section .funding-info-supercontainer {
    width: 100% !important;
    margin-top: 1rem;
  }
  .funding-section .form-deployed-container {
    display: unset;
    margin: 0;
    width: 100%;
  }
  .funding-section .funding-title {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .funding-section .funding-text-container {
    display: none;
  }
  .funding-section .imgs-container {
    margin-top: 3rem;
    height: 18rem;
  }
  .funding-section .deco-img-funding {
    display: none;
  }
  .funding-section .img-main-info {
    position: absolute;
    transform: scale(0.5);
    bottom: -9%;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .funding-section {
    margin: 0;
    position: relative;
    min-height: 39rem;
  }
  .funding-section .funding-bg-img {
    display: none;
  }
  .funding-section .funding-info-supercontainer {
    width: 100% !important;
  }
  .funding-section .form-deployed-container {
    display: unset;
    margin: 0;
    width: 100%;
  }
  .funding-section .funding-title {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .funding-section .funding-text-container {
    display: none;
  }
  .funding-section .imgs-container {
    margin-top: 3rem;
    height: 18rem;
  }
  .funding-section .deco-img-funding {
    display: none;
  }
  .funding-section .img-main-info {
    position: absolute;
    transform: scale(0.5);
    bottom: -9%;
  }
}
/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .funding-section {
    margin: 0;
    position: relative;
    height: 100%;
  }
  .funding-section .funding-info-supercontainer {
    width: 100% !important;
  }
  .funding-section .form-deployed-container {
    display: unset;
    margin: 0;
    width: 100%;
  }
  .funding-section .funding-title {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .funding-section .funding-text-container {
    display: none;
  }
  .funding-section .imgs-container {
    margin-top: 3rem;
    height: 18rem;
  }
  .funding-section .deco-img-funding {
    display: none;
  }
  .funding-section .img-main-info {
    position: absolute;
    transform: scale(0.5);
    bottom: -9%;
  }
}/*# sourceMappingURL=FundingSection.css.map */