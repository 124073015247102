@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.common-areas-section {
  z-index: 1;
  bottom: -50%;
  left: 0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
}
.common-areas-section .common-areas-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 3rem;
}
.common-areas-section .title-container {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.common-areas-section .title-container .title {
  font-size: 1.3rem;
  font-weight: lighter;
  color: #5c5c66;
  font-style: italic;
  background-color: #ffffff;
  padding: 0.25rem 1.5rem;
  border-radius: 2rem;
}
.common-areas-section .frame-area {
  width: 80%;
  height: 30rem;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
}
.common-areas-section .frame-area .ca-gallery-container {
  height: 100%;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .common-areas-subcontainer {
    width: 100% !important;
  }
  .title {
    font-size: 1.2rem !important;
  }
  .frame-area {
    width: 90vw !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .common-areas-subcontainer {
    width: 100% !important;
  }
  .title {
    font-size: 1.2rem !important;
  }
  .frame-area {
    width: 90vw !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .common-areas-subcontainer {
    width: 100% !important;
  }
}/*# sourceMappingURL=CommonAreasSection.css.map */