@import "../../sass/variables";

.btn-form-mb-container {
  position: fixed;
  z-index: 5;
  right: 1rem;
  bottom: 1.5rem;
  width: 3rem;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: $buttonColorPrimary;
  border-radius: 0.5rem;
  .anchor-btn-form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.5rem;
    .form-btn-icon {
      width: 100%;
      height: 100%;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .btn-form-mb-container {
    display: none;
    bottom: 6rem;
  }
}

//Responsive Mobile Standard - Tablet Small
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletStandard - 1px)) {
  .btn-form-mb-container {
    display: flex;
    bottom: 6rem;
  }
}
