@import "../../sass/variables";

.inside-view-section {
  position: relative;
  z-index: 2;
  scroll-margin-top: 4.5rem;
  overflow: hidden;
  height: 40rem;
  .inside-view-subcontainer {
    height: 100%;
  }
}