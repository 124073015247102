@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.form-wa-container {
  position: fixed;
  bottom: 7rem;
  right: -20rem;
  opacity: 0;
  z-index: 99;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
}
.form-wa-container .form-wa-input-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  cursor: pointer;
}
.form-wa-container label {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  outline: none;
}
.form-wa-container label .input-form-wa {
  height: 1.5rem;
  border: 1px solid grey;
  border-radius: 0.2rem;
  padding-left: 1rem;
}
.form-wa-container .label-advise {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: SarabunLightItalic;
  flex-direction: row;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.form-wa-container .anchor-check {
  color: #000000;
}
.form-wa-container .anchor-check:link {
  color: #000000;
  text-decoration: none;
}
.form-wa-container .anchor-check:visited {
  color: #000000;
}
.form-wa-container .anchor-check:active {
  color: #000000;
}
.form-wa-container .form-wa-btn {
  height: 1.5rem;
  border: 1px solid #00e942;
  background-color: #00e942;
  color: #ffffff;
  border-radius: 0.2rem;
  background-position: center;
  transition: background 0.8s;
  font-family: ClanProRgNews;
  display: flex;
  justify-content: center;
  padding: 0.25rem 0;
}
.form-wa-container .form-wa-btn:link {
  color: #ffffff;
  text-decoration: none;
}
.form-wa-container .form-wa-btn:visited {
  color: #ffffff;
}
.form-wa-container .form-wa-btn:hover {
  background-color: #06962e;
}
.form-wa-container .form-wa-btn:active {
  color: #ffffff;
}
.form-wa-container .mandatory-text {
  font-size: 0.8rem;
  color: #ff0007;
  font-family: SarabunLight;
}
.form-wa-container .hide {
  opacity: 0;
}

.open-wa-form {
  animation-name: openWaForm;
  animation-duration: 1s;
  right: 1.2rem;
  opacity: 1;
}
@keyframes openWaForm {
  0% {
    right: -20rem;
    opacity: 0;
  }
  100% {
    right: 1.2rem;
    opacity: 1;
  }
}

.close-wa-form {
  animation-name: closeWaForm;
  animation-duration: 1s;
  opacity: 0;
  right: -20rem;
}
@keyframes closeWaForm {
  0% {
    opacity: 0;
    right: 1.2rem;
  }
  100% {
    right: -20rem;
    opacity: 0;
  }
}

.wa-btn-container {
  height: 4rem;
  display: flex;
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  z-index: 100;
  align-items: center;
}
.wa-btn-container .wa-btn-icon-container {
  width: 3rem;
}
.wa-btn-container .wa-btn-icon-container .wa-icon {
  width: 100%;
  height: 100%;
}
.wa-btn-container .wa-hover-element {
  width: 70%;
  padding: 0.2rem 1rem;
  background-color: #00e942;
  color: #ffffff;
  border-radius: 0.5rem;
  font-family: SarabunLight;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: absolute;
  left: 40%;
  z-index: -1;
  opacity: 0;
}
.wa-btn-container .show {
  animation-name: showWATitle;
  animation-duration: 1s;
  opacity: 1;
}
@keyframes showWATitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.wa-btn-container .hide {
  animation-name: hide;
  animation-duration: 1s;
  opacity: 0;
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .wa-btn-container {
    bottom: 1rem;
  }
  .wa-btn-container .wa-btn-icon-container {
    width: 3rem !important;
  }
  .wa-btn-container .wa-hover-element {
    left: 35%;
  }
}/*# sourceMappingURL=WhatsappBtn.css.map */