@import "../../sass/variables";

.gallery-hor-section {
  z-index: 1;
  bottom: -50%;
  left: -0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
  .gallery-hor-subcontainer {
    width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-top: 5rem;
    row-gap: 2rem;
    .gh-title {
      font-size: 1.5rem;
      font-weight: lighter;
      font-style: italic;
      color: $mainColorPrimary;
      background-color: $basicColorPrimary;
      padding: 0.5rem 1.5rem;
      border-radius: 2rem;
    }
  }
  .fs-gallery-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    .reset-values-gallery {
      position: absolute;
      width: 6rem;
      height: 30rem;
      z-index: 9999;
      top: -35rem;
      cursor: pointer;
    }
  }
  .carousel-mobile-hor {
    display: none;
    z-index: 2;
    .btn-container {
      z-index: 3;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
    .btn-gallery {
      margin-right: 0.5rem;
      width: 6.5rem;
      height: 2rem;
      cursor: pointer;
      border-radius: 0.2rem;
      background-color: $buttonColorPrimary;
      border: 1px solid $buttonColorPrimary;
      color: $basicColorPrimary;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      background-position: center;
      transition: background 0.8s;
      font-family: $clanProRgNewsFont;
      font-size: 0.8rem;
      &:hover {
        background: $buttonColorPrimaryHover
          radial-gradient(circle, transparent 1%, $buttonColorPrimaryHover 1%)
          center/15000%;
        border: 1px solid $buttonColorPrimaryHover;
      }
      &:active {
        background-color: $buttonColorPrimaryWave;
        background-size: 100%;
        transition: background 0s;
      }
    }
    .btn-icon {
      width: 1rem;
      height: 1rem;
      margin-right: 0.3rem;
    }
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .gallery-hor-section {
    height: 26rem;
  }
  .gallery-hor-subcontainer {
    width: 100% !important;
    margin-top: 1rem !important;
    row-gap: 1rem !important;
  }
  .gallery-container {
    display: none !important;
  }
  .gh-title {
    font-size: 1.2rem !important;
  }
  .carousel-mobile-hor {
    display: unset !important;
    height: 18rem;
  }
}

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .gallery-hor-section {
    height: 22rem;
  }
  .gallery-hor-subcontainer {
    width: 100% !important;
    margin-top: 1rem !important;
    row-gap: 1rem !important;
  }
  .gallery-container {
    display: none !important;
  }
  .gh-title {
    font-size: 1.2rem !important;
  }
  .carousel-mobile-hor {
    display: unset !important;
    height: 18rem;
  }
}

/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .gallery-hor-subcontainer {
    width: 100% !important;
  }
  .gh-title {
    font-size: 1rem !important;
  }
}
