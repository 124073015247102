@import "../../sass/variables";

.footer-container {
  width: 100%;
  background-color: $bgFooterColorMain;
  padding: 3rem 0;
  position: relative;
  display: flex;
  justify-content: center;
  .footer-subcontainer {
    width: 1024px;
  }
  .preview-btn {
    position: absolute;
    top: -1.5rem;
    right: 10%;
    border: 1px solid $buttonColorPrimary;
    padding: 0.8rem 2.5rem;
    background-color: $buttonColorPrimary;
    color: $basicColorPrimary;
    border-radius: 0.2rem;
    background-position: center;
    transition: background 0.8s;
    font-family: $clanProRgNewsFont;
    z-index: 1;
    &:link {
      color: $basicColorPrimary;
      text-decoration: none;
    }
    &:visited {
      color: $basicColorPrimary;
    }
    &:hover {
      background: $buttonColorPrimaryHover
        radial-gradient(circle, transparent 1%, $buttonColorPrimaryHover 1%)
        center/15000%;
    }
    &:active {
      background-color: $buttonColorPrimaryWave;
      background-size: 100%;
      transition: background 0s;
    }
  }
  .otacc-info-footer {
    font-family: $sarabunMediumFont;
    .info-otacc {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .info-direction {
      display: flex;
      flex-direction: column;
    }
    .subtitle-style {
      font-weight: normal;
      color: $basicColorPrimary;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
    .sm-subtitle {
      border: 1px solid $basicColorPrimary;
      border-radius: 1rem;
      padding: 0.2rem 1.5rem;
      text-align: center;
      font-size: 1rem;
    }
    .sms-mobile {
      display: none;
    }
    .anchor-style {
      color: $basicColorPrimary;
      font-size: 0.9rem;
      margin-bottom: 0.2rem;
      &:link {
        color: $basicColorPrimary;
        text-decoration: none;
      }
      &:visited {
        color: $basicColorPrimary;
      }
      &:active {
        color: $basicColorPrimary;
      }
    }
    .anchor-sm {
      margin-right: 0.5rem;
    }
    .social-media-icons {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;
    }
    .social-media {
      width: 3rem;
      height: 3rem;
    }
  }
  .chat-btn-container {
    margin-top: 3rem;
  }
  .chat-btn {
    border: 1px solid $basicColorPrimary;
    padding: 0.2rem 2.5rem;
    border-radius: 1rem;
    font-family: $clanProRgNewsFont;
    &:link {
      color: $basicColorPrimary;
      text-decoration: none;
    }
    &:visited {
      color: $basicColorPrimary;
    }
    &:active {
      color: $basicColorPrimary;
      background-color: $basicColorPrimary;
      background-size: 100%;
      transition: background 0s;
    }
    &:hover {
      background: $basicColorPrimary
        radial-gradient(circle, transparent 1%, $buttonColorPrimary 1%)
        center/15000%;
      color: $mainColorPrimary;
    }
  }
  .copy {
    margin: 2.5rem 10% 0 0;
    font-size: 0.8rem;
    color: $basicColorPrimary;
  }
}

/*Responsive Mobile Small*/

@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .footer-container {
    .footer-subcontainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 5%;
    }
    .f-preview-btn-container {
      display: none;
    }
    .info-otacc {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
    }
    .otacc-info-footer {
      flex-direction: column;
      .info-direction {
        max-width: 15rem;
        text-align: center;
      }
    }
    .social-media-container {
      width: 100% !important;
      padding: 0 !important;
    }
    .subtitle-style {
      font-size: 1rem !important;
    }
    .sm-subtitle {
      font-size: 1rem !important;
    }
    .social-media-icons {
      justify-content: center !important;
    }
    .social-media {
      width: 100% !important;
      height: auto !important;
    }
    .chat-btn-container {
      display: flex;
      margin-top: 1rem;
    }
    .chat-btn {
      width: 100%;
      text-align: center;
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
    }
    .email-data-container {
      display: flex;
      align-items: center;
      column-gap: 0.25rem;
      justify-content: center;
      flex-direction: column;
    }
    .slash {
      display: none;
    }
    .sms-screen {
      display: none !important;
    }
    .sm-subtitle {
      font-size: 0.8rem;
    }
    .sms-mobile {
      display: unset !important;
    }
    .copy {
      margin: 2rem 0 0 0;
    }
  }
}

/*Responsive Mobile Standard*/

@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .footer-container {
    .footer-subcontainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .f-preview-btn-container {
      display: none;
    }
    .info-otacc {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
    }
    .otacc-info-footer {
      flex-direction: column;
      .info-direction {
        max-width: 18rem;
        text-align: center;
      }
    }
    .social-media-container {
      width: 100% !important;
      padding: 0 !important;
    }
    .sm-subtitle {
      font-size: 1.1rem !important;
    }
    .social-media-icons {
      justify-content: center !important;
      column-gap: 0.25rem;
    }
    .anchor-sm {
      margin: 0 !important;
    }
    .social-media {
      width: 100% !important;
      height: auto !important;
    }
    .chat-btn-container {
      display: flex;
      margin-top: 2rem;
    }
    .email-data-container {
      display: flex;
      align-items: center;
      column-gap: 0.25rem;
      justify-content: center;
    }
    .chat-btn {
      width: 100%;
      text-align: center;
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
    }
    .sms-screen {
      display: none !important;
    }
    .sm-subtitle {
      font-size: 0.8rem;
    }
    .sms-mobile {
      display: unset !important;
    }
    .copy {
      margin: 2.5rem 0 0 0;
    }
  }
}

/*Responsive Tablet Small*/

@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .footer-container {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .footer-subcontainer {
      width: 95%;
    }
    .f-preview-btn-container {
      display: none;
    }
    .otacc-info-footer {
      flex-direction: column;
      .info-otacc {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .info-direction {
        width: 100%;
      }
    }
    .social-media-container {
      width: 70% !important;
      align-self: center;
    }
    .sms-screen {
      display: none;
    }
    .sm-subtitle {
      font-size: 0.8rem;
    }
    .sms-mobile {
      display: unset !important;
    }
    .social-media-icons {
      justify-content: center !important;
    }
    .social-media {
      width: 100% !important;
      height: 100% !important;
    }
    .chat-btn-container {
      width: 55%;
      display: flex;
      justify-content: center;
      margin-left: 0;
    }
    .chat-btn {
      width: 100%;
      text-align: center;
      margin: 0;
    }
  }
}

/*Responsive Tablet Standard*/

@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm - 1px)) {
  .footer-container {
    .footer-subcontainer {
      margin: 0 5%;
    }
    .info-otacc {
      display: flex;
      column-gap: 1rem;
    }
    .social-media-container {
      width: 19rem;
      .social-media {
        width: 2.5rem;
        height: 2.5rem;
      }
      .sm-subtitle {
        font-size: 1rem;
      }
    }
  }
}

/*Responsive Screen Small*/

@media screen and (min-width: $screenSm) and (max-width: calc($screenHd - 1px)) {
  .sm-subtitle {
    font-size: 1.1rem !important;
  }
}
