@import "../../sass/variables";

.benefit-section {
  z-index: 1;
  bottom: -50%;
  left: -0%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 4.5rem;
}
.benefit-subcontainer {
  width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}
.benefit-title-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .title-section {
    font-size: 1.5rem;
    font-weight: lighter;
    color: $mainColorPrimary;
    background-color: $basicColorPrimary;
    padding: 0.6rem 2.5rem;
    font-style: italic;
    -webkit-border-bottom-right-radius: 1.5rem;
    -webkit-border-bottom-left-radius: 1.5rem;
    -moz-border-radius-bottomright: 1.5rem;
    -moz-border-radius-bottomleft: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
}
.benefit-location-icon-container {
  width: 100%;
  margin: 1.25rem 0;
  .bl-icon {
    display: flex;
    align-items: center;
    width: 6rem;
    padding: 0.2rem 1.5rem;
    background-color: $basicColorPrimary;
    font-size: 0.65rem;
    column-gap: 0.25rem;
    border-radius: 1rem;
    color: $mainColorPrimary;
    margin-left: 5%;
  }
  .bl-icon-img {
    width: auto;
    height: 1rem;
  }
}
.benefits-info-container {
  padding: 1.5rem 2%;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  column-gap: 1rem;
  .benefit-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
  }
  .benefit-icon {
    width: 5rem;
    height: 5rem;
  }
  .benefit-subtitle {
    font-size: 1rem;
    color: $basicColorPrimary;
    text-align: center;
  }
}
.carousel-mobile {
  display: none;
  height: 10rem;
}
.carousel-tablet {
  display: none;
}
.benefit-location-icon-container {
  display: flex;
  justify-content: space-between;
}
.bl-icon-copy {
  width: 13rem !important;
  justify-content: center;
}
.benefit-2024-txt-container {
  width: 100%;
  .b2024t-subcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.5rem;
    margin-top: 1rem;
    .b2024t-txt {
      width: 27rem;
      font-weight: lighter;
      color: $basicColorPrimary;
      font-size: 2rem;
      font-style: italic;
    }
    .b2024t-deco-img {
      width: 7rem;
      height: auto;
    }
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .benefit-img-bg {
    height: 29rem;
  }
  .benefit-subcontainer {
    width: 100%;
    row-gap: 1rem;
    .title-section {
      font-size: 1rem;
    }
    .benefits-info-container {
      display: none;
    }
    .carousel-mobile {
      display: unset;
      width: 100%;
    }
    .b2024t-subcontainer {
      margin: 1rem 5% 0;
      .b2024t-txt {
        font-size: 1rem !important;
        color: $mainColorPrimary;
      }
      .b2024t-deco-img {
        width: 5rem !important;
        height: auto;
      }
    }
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .benefit-img-bg {
    height: 30rem;
  }
  .benefit-subcontainer {
    width: 100%;
    row-gap: 1rem;
    .title-section {
      font-size: 1.2rem;
    }
    .benefits-info-container {
      display: none;
    }
    .carousel-tablet {
      display: unset;
      width: 100%;
    }
    .b2024t-subcontainer {
      margin: 1rem 0 0 3rem;
      .b2024t-txt {
        font-size: 1.5rem !important;
        color: $mainColorPrimary;
      }
      .b2024t-deco-img {
        width: 5rem !important;
        height: auto;
      }
    }
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .benefit-subcontainer {
    width: 100%;
    row-gap: 1rem;
  }
}
