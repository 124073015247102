@import "../../sass/variables";

.home-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .home-tag-container {
    width: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    .ht-subcontainer {
      width: 100%;
      position: relative;
      height: 560px;
    }
  }
  .tag-img {
    position: absolute;
    top: 3rem;
    right: 15rem;
    z-index: 1;
    width: 11rem;
    height: auto;
    transform: rotate(-16deg);
  }
}

//Responsive Mobile Small
@media screen and (min-width: $mobileSm) and (max-width: calc($mobileStandard - 1px)) {
  .home-section {
    height: 17rem;
  }
  .tag-img {
    display: none;
  }
}

//Responsive Mobile Standard
@media screen and (min-width: $mobileStandard) and (max-width: calc($tabletSm - 1px)) {
  .home-section {
    height: 17rem;
  }
  .tag-img {
    display: none;
  }
}

//Responsive Tablet Small
@media screen and (min-width: $tabletSm) and (max-width: calc($tabletStandard - 1px)) {
  .tag-img {
    display: none;
  }
}

//Responsive Tablet Standard
@media screen and (min-width: $tabletStandard) and (max-width: calc($screenSm)) {
  .tag-img {
    width: 10rem !important;
    top: 5rem !important;
    right: 2rem !important;
  }
}

//Responsive Screen Small
@media screen and (min-width: $screenSm) and (max-width: calc($screenHd)) {
  .tag-img {
    width: 10rem !important;
    right: 3rem !important;
  }
}
