@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");
/*Fonts*/
@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}
@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}
@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}
@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}
/*Colors*/
/*Colors Transparency*/
/*Hover Colors*/
/*Button Color Effect*/
/*Styles*/
* {
  margin: 0;
}

/*Responsive - breakpoints*/
.footer-container {
  width: 100%;
  background-color: #3f3f3f;
  padding: 3rem 0;
  position: relative;
  display: flex;
  justify-content: center;
}
.footer-container .footer-subcontainer {
  width: 1024px;
}
.footer-container .preview-btn {
  position: absolute;
  top: -1.5rem;
  right: 10%;
  border: 1px solid #6bb301;
  padding: 0.8rem 2.5rem;
  background-color: #6bb301;
  color: #ffffff;
  border-radius: 0.2rem;
  background-position: center;
  transition: background 0.8s;
  font-family: ClanProRgNews;
  z-index: 1;
}
.footer-container .preview-btn:link {
  color: #ffffff;
  text-decoration: none;
}
.footer-container .preview-btn:visited {
  color: #ffffff;
}
.footer-container .preview-btn:hover {
  background: #90da21 radial-gradient(circle, transparent 1%, #90da21 1%) center/15000%;
}
.footer-container .preview-btn:active {
  background-color: #e2d3bc;
  background-size: 100%;
  transition: background 0s;
}
.footer-container .otacc-info-footer {
  font-family: SarabunMedium;
}
.footer-container .otacc-info-footer .info-otacc {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.footer-container .otacc-info-footer .info-direction {
  display: flex;
  flex-direction: column;
}
.footer-container .otacc-info-footer .subtitle-style {
  font-weight: normal;
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.footer-container .otacc-info-footer .sm-subtitle {
  border: 1px solid #ffffff;
  border-radius: 1rem;
  padding: 0.2rem 1.5rem;
  text-align: center;
  font-size: 1rem;
}
.footer-container .otacc-info-footer .sms-mobile {
  display: none;
}
.footer-container .otacc-info-footer .anchor-style {
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}
.footer-container .otacc-info-footer .anchor-style:link {
  color: #ffffff;
  text-decoration: none;
}
.footer-container .otacc-info-footer .anchor-style:visited {
  color: #ffffff;
}
.footer-container .otacc-info-footer .anchor-style:active {
  color: #ffffff;
}
.footer-container .otacc-info-footer .anchor-sm {
  margin-right: 0.5rem;
}
.footer-container .otacc-info-footer .social-media-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.footer-container .otacc-info-footer .social-media {
  width: 3rem;
  height: 3rem;
}
.footer-container .chat-btn-container {
  margin-top: 3rem;
}
.footer-container .chat-btn {
  border: 1px solid #ffffff;
  padding: 0.2rem 2.5rem;
  border-radius: 1rem;
  font-family: ClanProRgNews;
}
.footer-container .chat-btn:link {
  color: #ffffff;
  text-decoration: none;
}
.footer-container .chat-btn:visited {
  color: #ffffff;
}
.footer-container .chat-btn:active {
  color: #ffffff;
  background-color: #ffffff;
  background-size: 100%;
  transition: background 0s;
}
.footer-container .chat-btn:hover {
  background: #ffffff radial-gradient(circle, transparent 1%, #6bb301 1%) center/15000%;
  color: #5c5c66;
}
.footer-container .copy {
  margin: 2.5rem 10% 0 0;
  font-size: 0.8rem;
  color: #ffffff;
}

/*Responsive Mobile Small*/
@media screen and (min-width: 320px) and (max-width: 479px) {
  .footer-container .footer-subcontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5%;
  }
  .footer-container .f-preview-btn-container {
    display: none;
  }
  .footer-container .info-otacc {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
  .footer-container .otacc-info-footer {
    flex-direction: column;
  }
  .footer-container .otacc-info-footer .info-direction {
    max-width: 15rem;
    text-align: center;
  }
  .footer-container .social-media-container {
    width: 100% !important;
    padding: 0 !important;
  }
  .footer-container .subtitle-style {
    font-size: 1rem !important;
  }
  .footer-container .sm-subtitle {
    font-size: 1rem !important;
  }
  .footer-container .social-media-icons {
    justify-content: center !important;
  }
  .footer-container .social-media {
    width: 100% !important;
    height: auto !important;
  }
  .footer-container .chat-btn-container {
    display: flex;
    margin-top: 1rem;
  }
  .footer-container .chat-btn {
    width: 100%;
    text-align: center;
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
  }
  .footer-container .email-data-container {
    display: flex;
    align-items: center;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
    justify-content: center;
    flex-direction: column;
  }
  .footer-container .slash {
    display: none;
  }
  .footer-container .sms-screen {
    display: none !important;
  }
  .footer-container .sm-subtitle {
    font-size: 0.8rem;
  }
  .footer-container .sms-mobile {
    display: unset !important;
  }
  .footer-container .copy {
    margin: 2rem 0 0 0;
  }
}
/*Responsive Mobile Standard*/
@media screen and (min-width: 480px) and (max-width: 767px) {
  .footer-container .footer-subcontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-container .f-preview-btn-container {
    display: none;
  }
  .footer-container .info-otacc {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }
  .footer-container .otacc-info-footer {
    flex-direction: column;
  }
  .footer-container .otacc-info-footer .info-direction {
    max-width: 18rem;
    text-align: center;
  }
  .footer-container .social-media-container {
    width: 100% !important;
    padding: 0 !important;
  }
  .footer-container .sm-subtitle {
    font-size: 1.1rem !important;
  }
  .footer-container .social-media-icons {
    justify-content: center !important;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
  }
  .footer-container .anchor-sm {
    margin: 0 !important;
  }
  .footer-container .social-media {
    width: 100% !important;
    height: auto !important;
  }
  .footer-container .chat-btn-container {
    display: flex;
    margin-top: 2rem;
  }
  .footer-container .email-data-container {
    display: flex;
    align-items: center;
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
    justify-content: center;
  }
  .footer-container .chat-btn {
    width: 100%;
    text-align: center;
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
  }
  .footer-container .sms-screen {
    display: none !important;
  }
  .footer-container .sm-subtitle {
    font-size: 0.8rem;
  }
  .footer-container .sms-mobile {
    display: unset !important;
  }
  .footer-container .copy {
    margin: 2.5rem 0 0 0;
  }
}
/*Responsive Tablet Small*/
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footer-container {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-container .footer-subcontainer {
    width: 95%;
  }
  .footer-container .f-preview-btn-container {
    display: none;
  }
  .footer-container .otacc-info-footer {
    flex-direction: column;
  }
  .footer-container .otacc-info-footer .info-otacc {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .footer-container .otacc-info-footer .info-direction {
    width: 100%;
  }
  .footer-container .social-media-container {
    width: 70% !important;
    align-self: center;
  }
  .footer-container .sms-screen {
    display: none;
  }
  .footer-container .sm-subtitle {
    font-size: 0.8rem;
  }
  .footer-container .sms-mobile {
    display: unset !important;
  }
  .footer-container .social-media-icons {
    justify-content: center !important;
  }
  .footer-container .social-media {
    width: 100% !important;
    height: 100% !important;
  }
  .footer-container .chat-btn-container {
    width: 55%;
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
  .footer-container .chat-btn {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}
/*Responsive Tablet Standard*/
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .footer-container .footer-subcontainer {
    margin: 0 5%;
  }
  .footer-container .info-otacc {
    display: flex;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  .footer-container .social-media-container {
    width: 19rem;
  }
  .footer-container .social-media-container .social-media {
    width: 2.5rem;
    height: 2.5rem;
  }
  .footer-container .social-media-container .sm-subtitle {
    font-size: 1rem;
  }
}
/*Responsive Screen Small*/
@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .sm-subtitle {
    font-size: 1.1rem !important;
  }
}/*# sourceMappingURL=Footer.css.map */