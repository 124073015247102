@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,700&display=swap");

/*Fonts*/

@font-face {
  font-family: AstralSisters;
  src: url("../../fonts/Astral-Sisters.ttf");
  font-display: swap;
}

@font-face {
  font-family: ClanProBlk;
  src: url("../../fonts/FFClanProBlk.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProBold;
  src: url("../../fonts/FFClanProBold.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProMedium;
  src: url("../../fonts/FFClanProMedium.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRegular;
  src: url("../../fonts/FFClanProRegular.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProRgNews;
  src: url("../../fonts/FFClanProRgNews.TTF");
  font-display: swap;
}

@font-face {
  font-family: ClanProUltra;
  src: url("../../fonts/FFClanProUltra.TTF");
  font-display: swap;
}

@font-face {
  font-family: SarabunBold;
  src: url("../../fonts/Sarabun-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunExtraBold;
  src: url("../../fonts/Sarabun-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLight;
  src: url("../../fonts/Sarabun-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunLightItalic;
  src: url("../../fonts/Sarabun-LightItalic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunMedium;
  src: url("../../fonts/Sarabun-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunRegular;
  src: url("../../fonts/Sarabun-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: SarabunSemiBold;
  src: url("../../fonts/Sarabun-SemiBold.ttf");
  font-display: swap;
}

$astralSistersFont: AstralSisters;
$clanProBlkFont: ClanProBlk;
$clanProBoldFont: ClanProBold;
$clanProMediuFont: ClanProMedium;
$clanProRegularFont: ClanProRegular;
$clanProRgNewsFont: ClanProRgNews;
$clanProUltraFont: ClanProUltra;
$sarabunBoldFont: SarabunBold;
$sarabunExtraBoldFont: SarabunExtraBold;
$sarabunLightFont: SarabunLight;
$sarabunLightItalicFont: SarabunLightItalic;
$sarabunMediumFont: SarabunMedium;
$sarabunRegularFont: SarabunRegular;
$sarabunSemiBoldFont: SarabunSemiBold;
$playFairFont: "Playfair Display", serif;

/*Colors*/

$basicColorPrimary: #ffffff;
$basicColorSecondary: #000000;
$mainColorPrimary: #5c5c66;
$buttonColorPrimary: #6bb301;
$buttonColorSecondary: #8ac2b7;
$mainColorSecondary: #243455;
$wazeButtonColor: #ebe5c5;
$errorMsgColor: #ff0007;
$subtitleColor: #ff6601;
$whatsappBtnColor: #00e942;
$borderWhatsappForm: grey;
$bgColorMain: #f9f5f6;
$bgFooterColorMain: #3f3f3f;

/*Colors Transparency*/
$mainColorPrimaryOpa: #564c4388;
$buttonColorPrimaryOpa: #6cb3012d;
$bgNavbaropa: #ffffffb9;

/*Hover Colors*/
$mainColorPrimaryHover: #312d29;
$buttonColorPrimaryHover: #90da21;
$whatsappBtnColorHover: #06962e;

/*Button Color Effect*/
$buttonColorPrimaryWave: #e2d3bc;
$buttonColorSecondaryWave: #ccb697;
$buttonFooterWave: #495264;

/*Styles*/

* {
  margin: 0;
}

/*Responsive - breakpoints*/
$mobileSm: 320px;
$mobileStandard: 480px;
$tabletSm: 768px;
$tabletStandard: 1024px;
$screenSm: 1200px;
$screenHd: 1920px;
$screen4k: 2160px;
